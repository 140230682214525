import gql from 'graphql-tag';

const ConfluencePageFragment = gql`
	fragment ConfluencePageFragment on ConfluencePage {
		id
		pageId
		links {
			base
			webUi
		}
		title
		space {
			id
			spaceId
			name
			links {
				base
				webUi
			}
		}
	}
`;

const ConfluenceBlogFragment = gql`
	fragment ConfluenceBlogFragment on ConfluenceBlogPost {
		id
		blogPostId
		links {
			base
			webUi
		}
		title
		space {
			id
			spaceId
			name
			links {
				base
				webUi
			}
		}
	}
`;

export const TasksQuery = gql`
	query TasksQuery($taskQuery: InlineTasksByMetadata!) {
		inlineTasks(tasksQuery: $taskQuery) {
			inlineTasks {
				id
				taskId
				status
				createdBy {
					accountId
					displayName
				}
				createdAt
				dueAt
				assignedTo {
					accountId
					displayName
					profilePicture {
						path
					}
				}
				body {
					adf
				}
				container {
					__typename
					...ConfluencePageFragment
					...ConfluenceBlogFragment
				}
			}
			endCursor
		}
	}
	${ConfluencePageFragment}
	${ConfluenceBlogFragment}
`;
