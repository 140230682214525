import { NetworkStatus } from 'apollo-client';
import { defineMessages } from 'react-intl-next';

export enum LoadingState {
	done,
	initial,
	pagination,
}

export enum HomeViewFilters {
	ALL = 'ALL',
	WORKED_ON = 'WORKED_ON',
	CREATED = 'CREATED',
	STARRED = 'STARRED',
	TASKS = 'TASKS',
	DRAFTS = 'DRAFTS',
}

const i18n = defineMessages({
	untitled: {
		id: 'home.views.untitled',
		defaultMessage: 'Untitled',
		description: 'Title displayed for a page that does not have a title',
	},
});
export const untitled = i18n.untitled;

export const getLoadingState = (networkStatus: NetworkStatus) => {
	if (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables) {
		return LoadingState.initial;
	}
	if (networkStatus === NetworkStatus.fetchMore) {
		return LoadingState.pagination;
	}
	return LoadingState.done;
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined;
}

export const byTitle = (textFilter: string, title?: string | null) => {
	const trimmedTextFilter = textFilter.trim();
	if (trimmedTextFilter === '') {
		return true;
	}

	return (title || '').toUpperCase().indexOf(textFilter.toUpperCase()) > -1;
};

export const notBlankDraft = (content: any) => {
	return !(content?.status === 'draft' && content?.blank);
};

export const notDeleted = (content: any) => {
	return !(content?.status === 'trashed');
};

export const isCreatorCurrentUser = (userId: string | null, content: any) => {
	const createdBy =
		content?.status === 'draft' ? content?.version?.by : content?.history?.createdBy;
	return userId === createdBy?.accountId;
};
