import type {
	TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage as ConfluencePage,
	TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage_space as ConfluencePageSpace,
	TasksQuery_inlineTasks_inlineTasks_container_ConfluenceBlogPost_space as ConfluenceBlogSpace,
} from './__types__/TasksQuery';

export enum TaskColumn {
	Page = 'page',
	Space = 'space',
	Creator = 'creator',
	Assignee = 'assignee',
}

export enum ColumnWidths {
	Description = 610,
	Main = 270,
}

export enum TaskView {
	Assigned = 'assigned-to-me',
	Created = 'created-by-me',
}

// Used to determine what to sort the rows by.
export enum SortTypes {
	Page = 'page',
	Date = 'date',
}

// Used to determine the direction of the sort.
export enum SortOptions {
	Ascending = 'ASC',
	Descending = 'DESC',
	Unsorted = 'DEFAULT',
}

export type TaskPages = Record<
	string, // PageId
	ConfluencePage
>;

type ConfluenceSpace = ConfluenceBlogSpace | ConfluencePageSpace;

export type TaskSpaces = Record<
	string, // SpaceId
	ConfluenceSpace
>;
