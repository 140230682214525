import type { OptionData } from '@atlaskit/user-picker';

export enum CompletionOptions {
	DONE = 'done',
	ONGOING = 'ongoing',
	NO = 'no',
}

export enum DueDateSelectOptions {
	OVERDUE = 'overdue',
	ONE_WEEK = 'one-week',
	TWO_WEEKS = 'two-weeks',
	ONE_MONTH = 'one-month',
	NO_DATE = 'no-date',
	CUSTOM = 'custom',
}

export interface CustomDateOption {
	from: string;
	to: string;
	range: string;
}

export type DueDateOptions = DueDateSelectOptions | CustomDateOption;

export enum FilterKeys {
	DUE_DATE = 'dueDate',
	COMPLETED = 'completed',
	CREATOR = 'creator',
	ASSIGNEE = 'assignee',
	PAGE = 'page',
	SPACE = 'space',
	DEFAULT = 'default',
}

export interface Filters {
	[FilterKeys.DUE_DATE]?: DueDateOptions;
	[FilterKeys.COMPLETED]?: CompletionOptions;
	[FilterKeys.CREATOR]?: OptionData[];
	[FilterKeys.ASSIGNEE]?: OptionData[];
	[FilterKeys.PAGE]?: string[];
	[FilterKeys.SPACE]?: string[];
}

export const defaultFilters: Filters = {
	[FilterKeys.COMPLETED]: CompletionOptions.NO,
};

export interface SelectOptions<T> {
	label: string;
	value: T; // The optionTypes.
}
