import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Badge from '@atlaskit/badge';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { HOME } from '@confluence/named-routes';
import { useRouteDataRef, useRouteUrl } from '@confluence/route-manager';
import { SSRMouseEventWrapper, SSR_NAV_TASKS_BUTTON_METRIC } from '@confluence/browser-metrics';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import {
	TaskView,
	SortTypes,
	SortOptions,
	useFiltersReducer,
	useGetTasksQuery,
	LoadingState,
} from '@confluence/home/entry-points/Tasks';

import { TaskIconComponent } from '../GlobalNavigationIcons';
import { i18n } from '../globalNavigationTranslations';

import { useGlobalItemVisibility } from './useGlobalItemVisibility';
import type { GlobalItemProps } from './globalItemProps';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireTasksClickedAnalytics,
);

const onTasksSelector = {
	selector: (routeUrl: string | undefined) => {
		if (!routeUrl) {
			return false;
		}
		return routeUrl === HOME.toUrl({ view: 'tasks' });
	},
};

export const TasksItem: FC<GlobalItemProps> = ({ isHidden, peekingId, setPeekingId }) => {
	const routeDataRef = useRouteDataRef();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const isSelected = useRouteUrl(onTasksSelector);
	const [filterState] = useFiltersReducer();
	const { tasks, loadingState, error } = useGetTasksQuery({
		filters: filterState.filters,
		taskView: TaskView.Assigned,
		sortType: SortTypes.Date,
		sortOption: SortOptions.Ascending,
	});

	let count = '-';

	if (!error && loadingState === LoadingState.done) {
		if (tasks.length > 99) {
			count = '99+';
		} else {
			count = String(tasks.length);
		}
	}

	const shouldHide = useGlobalItemVisibility(
		'tasks',
		isSelected,
		isHidden,
		peekingId,
		setPeekingId,
	);

	if (shouldHide) {
		return null;
	}

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_TASKS_BUTTON_METRIC}>
			<MenuLinkItem
				href="#"
				elemBefore={TaskIconComponent}
				elemAfter={<Badge appearance="default">{count}</Badge>}
				isSelected={isSelected}
				onClick={fireClickAnalytics}
			>
				<FormattedMessage {...i18n.tasks} />
			</MenuLinkItem>
		</SSRMouseEventWrapper>
	);
};
