import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';
import { add } from 'date-fns';
import { useMemo } from 'react';

import { useSessionData } from '@confluence/session-data';

import { getLoadingState } from '../homeViewsQueryHelpers';
import type { LoadingState } from '../homeViewsQueryHelpers';

import {
	TaskStatus,
	InlineTasksQuerySortColumn,
	InlineTasksQuerySortOrder,
} from './__types__/TasksQuery';
import { TasksQuery } from './GetTasks.graphql';
import type { CustomDateOption, DueDateOptions, Filters } from './Filters/filtersDefinitions';
import { DueDateSelectOptions, CompletionOptions, FilterKeys } from './Filters/filtersDefinitions';
import { TaskView, SortTypes, SortOptions } from './tasksDefinitions';
import type {
	InlineTasksQueryAccountIds,
	TasksQuery as TasksQueryType,
	TasksQueryVariables,
	TasksQuery_inlineTasks_inlineTasks as Task,
	InlineTasksByMetadata,
	InlineTasksQueryDateRange,
	InlineTasksQuerySortParameters,
} from './__types__/TasksQuery';

interface UseGetTasksQueryProps {
	filters: Filters;
	taskView: TaskView;
	sortType?: SortTypes; // Used to determine which column to sort by.
	sortOption?: SortOptions; // Used to determine which direction to sort.
}

interface UseGetTasksQueryResults {
	tasks: Task[];
	loading: boolean;
	error?: ApolloError;
	loadingState: LoadingState; // Network state from apollo to local enum.
	getTasks: () => void; // Namespaced fetchMore function from useQuery
	hasNextPage: boolean; // Returned by API to determine whether or not current pagination has next page.
	refetch: () => void; // Refetch using the same query and page
}

const getStatus = (completed?: CompletionOptions): TaskStatus | undefined => {
	switch (completed) {
		case CompletionOptions.DONE:
			return TaskStatus.CHECKED;
		case CompletionOptions.ONGOING:
			return TaskStatus.UNCHECKED;
	}

	return undefined;
};

/**
 * There's a "bug" where switching between views can revert back to the first "page" of tasks since
 * we don't actually use a cursor to manage where we last fetched. To fix this for now, we likely have to update
 * the PAGE_SIZE if our cursor exceeds the PAGE_SIZE so we can hit the larger cached query.
 */
export const PAGE_SIZE = 200;

export const isDueDateSelectOption = (option: DueDateOptions): option is DueDateSelectOptions => {
	return Object.values(DueDateSelectOptions).some((dueDateOption) => dueDateOption === option);
};

const getDueDateRange = (option?: DueDateOptions): InlineTasksQueryDateRange | undefined => {
	if (!option) {
		return;
	}

	if (isDueDateSelectOption(option)) {
		switch (option) {
			case DueDateSelectOptions.OVERDUE:
				return {
					endDate: new Date().getTime(),
				};
			case DueDateSelectOptions.ONE_WEEK:
				return {
					startDate: new Date().getTime(),
					endDate: add(new Date(), { weeks: 1 }).getTime(),
				};
			case DueDateSelectOptions.TWO_WEEKS:
				return {
					startDate: new Date().getTime(),
					endDate: add(new Date(), { weeks: 2 }).getTime(),
				};
			case DueDateSelectOptions.ONE_MONTH:
				return {
					startDate: new Date().getTime(),
					endDate: add(new Date(), { months: 1 }).getTime(),
				};
		}
	} else {
		const { from, to } = option as CustomDateOption;
		if (from && to) {
			return {
				startDate: new Date(from).getTime(),
				endDate: new Date(to).getTime(),
			};
		}
	}

	return;
};

const getAccountIds = (
	taskView: TaskView,
	userId: string | null,
	filters: Filters,
): InlineTasksQueryAccountIds => {
	const accountIds: InlineTasksQueryAccountIds = {};

	if (taskView === TaskView.Assigned) {
		// Creator Input for Assigned view
		if (filters[FilterKeys.CREATOR] && filters[FilterKeys.CREATOR]!.length > 0) {
			accountIds.creatorAccountIds = filters[FilterKeys.CREATOR]?.map((creator) => creator.id);
		}

		if (userId) {
			accountIds.assigneeAccountIds = [userId];
		}

		return accountIds;
	}

	if (taskView === TaskView.Created) {
		// Assignee Input for Creator View.
		if (filters[FilterKeys.ASSIGNEE] && filters[FilterKeys.ASSIGNEE]!.length > 0) {
			accountIds.assigneeAccountIds = filters[FilterKeys.ASSIGNEE]?.map((assignee) => assignee.id);
		}

		if (userId) {
			accountIds.creatorAccountIds = [userId];
		}
	}

	return accountIds;
};

type GetTaskQueryProps = UseGetTasksQueryProps & {
	userId: string | null;
};

// We need to memoize this because apollo will keep calculating new times, triggering new queries in an infinite loop
// because they are technically different query values each time.
// trigger this based off filters so not to run into edge case of only updating once.
const useDueDateRange = (filters: Filters): InlineTasksQueryDateRange | undefined => {
	return useMemo(() => {
		return getDueDateRange(filters[FilterKeys.DUE_DATE]);
	}, [filters]);
};

const getSortParameters = (
	sortType?: SortTypes,
	sortOption?: SortOptions,
): InlineTasksQuerySortParameters | undefined => {
	if (sortType && sortOption) {
		return {
			sortColumn:
				sortType === SortTypes.Page
					? InlineTasksQuerySortColumn.PAGE_TITLE
					: InlineTasksQuerySortColumn.DUE_DATE,
			sortOrder:
				sortOption === SortOptions.Ascending || sortOption === SortOptions.Unsorted
					? InlineTasksQuerySortOrder.ASCENDING
					: InlineTasksQuerySortOrder.DESCENDING,
		};
	}
};

// Builds the InlineTasksByMetadata object used to query
export const useTaskQuery = ({
	taskView,
	filters,
	userId,
	sortType,
	sortOption,
}: GetTaskQueryProps): InlineTasksByMetadata => {
	const accountIds = getAccountIds(taskView, userId, filters);
	const spaceIds = filters[FilterKeys.SPACE];
	const pageIds = filters[FilterKeys.PAGE];
	const dueDate = useDueDateRange(filters);
	const sortParameters = getSortParameters(sortType, sortOption);

	return {
		first: PAGE_SIZE,
		accountIds,
		dueDate,
		sortParameters,
		isNoDueDate: filters[FilterKeys.DUE_DATE] === DueDateSelectOptions.NO_DATE,
		status: getStatus(filters[FilterKeys.COMPLETED]),
		spaceIds: spaceIds?.length ? spaceIds : undefined,
		pageIds: pageIds?.length ? pageIds : undefined,
	};
};

/**
 * Update query results based on fetchMore results.
 * @param prev Previous state.
 * @param param1 Options passed, fetchMoreResult key is the result of the fetch more.
 * @returns whatever returned becomes the new state returned by the query.
 */
export const updateQuery = (
	prev: TasksQueryType,
	{ fetchMoreResult }: { fetchMoreResult?: TasksQueryType | undefined },
): TasksQueryType => {
	if (fetchMoreResult) {
		const taskData = fetchMoreResult?.inlineTasks;
		const newTasks = (taskData?.inlineTasks as Task[]) ?? [];

		return {
			...prev,
			inlineTasks: {
				...prev?.inlineTasks,
				inlineTasks: [...(prev?.inlineTasks?.inlineTasks ?? []), ...newTasks],
				endCursor: taskData?.endCursor ?? null,
			},
		};
	}

	return prev;
};

export const useGetTasksQuery = ({
	taskView,
	filters,
	sortType,
	sortOption,
}: UseGetTasksQueryProps): UseGetTasksQueryResults => {
	const { userId } = useSessionData();
	const taskQuery = useTaskQuery({
		userId,
		taskView,
		filters,
		sortType,
		sortOption,
	});
	const { networkStatus, data, error, loading, fetchMore, refetch } = useQuery<
		TasksQueryType,
		TasksQueryVariables
	>(TasksQuery, {
		variables: {
			taskQuery,
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
	});

	const taskData = data?.inlineTasks;
	const tasks = (taskData?.inlineTasks as Task[]) ?? [];
	const endCursor = taskData?.endCursor;

	const fetchTasks = async () => {
		if (!loading && !error && endCursor) {
			await fetchMore({
				variables: {
					taskQuery: {
						...taskQuery,
						after: endCursor,
					},
				},
				updateQuery,
			});
		}
	};

	return {
		tasks: (tasks as Task[]) ?? [],
		loading,
		loadingState: getLoadingState(networkStatus),
		error,
		getTasks: fetchTasks,
		hasNextPage: !!endCursor,
		refetch,
	};
};
