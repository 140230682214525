import { useReducer } from 'react';
import type { Reducer } from 'react';

import { FilterKeys, CompletionOptions } from './filtersDefinitions';
import type { Filters } from './filtersDefinitions';

// An action to dispatch to change state of filter.
export interface FiltersAction {
	type: FilterKeys; // The filter to change
	payload?: any; // Any data you need to change the specific filter.
}

// Used in Filters reducer to manage state and count.
export interface FiltersState {
	count: number;
	filters: Filters;
}

type FilterReducer = Reducer<FiltersState, FiltersAction>;

export const initialFilterState: FiltersState = {
	filters: {
		[FilterKeys.COMPLETED]: CompletionOptions.ONGOING,
	},
	count: 0,
};

const getAppliedFiltersCount = (filters: Filters): number =>
	Object.values(filters).reduce((previous, filterValue) => {
		if (filterValue === CompletionOptions.ONGOING) {
			return previous;
		}
		if (Array.isArray(filterValue)) {
			return previous + (filterValue.length ? 1 : 0);
		}

		return filterValue ? previous + 1 : previous;
	}, 0);

const getCustomDateRange = (from, to) => {
	if (!from || !to) {
		return from || to;
	}

	// check which one should be first since user might not choose chronologically
	const dateFrom = new Date(from);
	const dateTo = new Date(to);

	if (dateFrom > dateTo) {
		return `${to} - ${from}`;
	} else {
		return `${from} - ${to}`;
	}
};

const filterReducer: FilterReducer = (state, action) => {
	let filters;
	switch (action.type) {
		case FilterKeys.DEFAULT:
			return initialFilterState;
		case FilterKeys.SPACE:
		case FilterKeys.PAGE:
		case FilterKeys.CREATOR:
		case FilterKeys.ASSIGNEE:
		case FilterKeys.COMPLETED:
			filters = {
				...state.filters,
				[action.type]: action.payload,
			};
			return {
				...state,
				filters,
				count: getAppliedFiltersCount(filters),
			};
		case FilterKeys.DUE_DATE:
			const { payload } = action;

			if (!payload) return state;

			if (payload.from || payload.to) {
				// there's a custom date range
				filters = {
					...state.filters,
					[action.type]: {
						from: payload.from,
						to: payload.to,
						range: getCustomDateRange(payload.from, payload.to),
					},
				};
			} else {
				filters = {
					...state.filters,
					[action.type]: action.payload,
				};
			}

			return {
				...state,
				filters,
				count: getAppliedFiltersCount(filters),
			};
		default:
			return state;
	}
};

export const useFiltersReducer = () => useReducer<FilterReducer>(filterReducer, initialFilterState);
