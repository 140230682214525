/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ConfluenceInlineTaskStatus {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
}

export enum InlineTasksQuerySortColumn {
  ASSIGNEE = "ASSIGNEE",
  DUE_DATE = "DUE_DATE",
  PAGE_TITLE = "PAGE_TITLE",
}

export enum InlineTasksQuerySortOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum TaskStatus {
  CHECKED = "CHECKED",
  UNCHECKED = "UNCHECKED",
}

export interface InlineTasksByMetadata {
  accountIds?: InlineTasksQueryAccountIds | null;
  after?: string | null;
  completedDateRange?: InlineTasksQueryDateRange | null;
  createdDateRange?: InlineTasksQueryDateRange | null;
  dueDate?: InlineTasksQueryDateRange | null;
  first: number;
  forCurrentPageOnly?: boolean | null;
  isNoDueDate?: boolean | null;
  pageIds?: (any | null)[] | null;
  sortParameters?: InlineTasksQuerySortParameters | null;
  spaceIds?: (any | null)[] | null;
  status?: TaskStatus | null;
}

export interface InlineTasksQueryAccountIds {
  assigneeAccountIds?: (string | null)[] | null;
  completedByAccountIds?: (string | null)[] | null;
  creatorAccountIds?: (string | null)[] | null;
}

export interface InlineTasksQueryDateRange {
  endDate?: any | null;
  startDate?: any | null;
}

export interface InlineTasksQuerySortParameters {
  sortColumn: InlineTasksQuerySortColumn;
  sortOrder: InlineTasksQuerySortOrder;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TasksQuery
// ====================================================

export interface TasksQuery_inlineTasks_inlineTasks_createdBy {
  accountId: string;
  displayName: string | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_assignedTo_profilePicture {
  path: string;
}

export interface TasksQuery_inlineTasks_inlineTasks_assignedTo {
  accountId: string;
  displayName: string | null;
  profilePicture: TasksQuery_inlineTasks_inlineTasks_assignedTo_profilePicture | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_body {
  adf: string | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage_links {
  base: string | null;
  webUi: string | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage_space_links {
  base: string | null;
  webUi: string | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage_space {
  id: string;
  spaceId: string;
  name: string | null;
  links: TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage_space_links | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage {
  __typename: "ConfluencePage";
  id: string;
  pageId: string;
  links: TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage_links | null;
  title: string | null;
  space: TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage_space | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_container_ConfluenceBlogPost_links {
  base: string | null;
  webUi: string | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_container_ConfluenceBlogPost_space_links {
  base: string | null;
  webUi: string | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_container_ConfluenceBlogPost_space {
  id: string;
  spaceId: string;
  name: string | null;
  links: TasksQuery_inlineTasks_inlineTasks_container_ConfluenceBlogPost_space_links | null;
}

export interface TasksQuery_inlineTasks_inlineTasks_container_ConfluenceBlogPost {
  __typename: "ConfluenceBlogPost";
  id: string;
  blogPostId: string;
  links: TasksQuery_inlineTasks_inlineTasks_container_ConfluenceBlogPost_links | null;
  title: string | null;
  space: TasksQuery_inlineTasks_inlineTasks_container_ConfluenceBlogPost_space | null;
}

export type TasksQuery_inlineTasks_inlineTasks_container = TasksQuery_inlineTasks_inlineTasks_container_ConfluencePage | TasksQuery_inlineTasks_inlineTasks_container_ConfluenceBlogPost;

export interface TasksQuery_inlineTasks_inlineTasks {
  id: string;
  taskId: string | null;
  status: ConfluenceInlineTaskStatus | null;
  createdBy: TasksQuery_inlineTasks_inlineTasks_createdBy | null;
  createdAt: string | null;
  dueAt: string | null;
  assignedTo: TasksQuery_inlineTasks_inlineTasks_assignedTo | null;
  body: TasksQuery_inlineTasks_inlineTasks_body | null;
  container: TasksQuery_inlineTasks_inlineTasks_container | null;
}

export interface TasksQuery_inlineTasks {
  inlineTasks: (TasksQuery_inlineTasks_inlineTasks | null)[] | null;
  endCursor: string | null;
}

export interface TasksQuery {
  inlineTasks: TasksQuery_inlineTasks | null;
}

export interface TasksQueryVariables {
  taskQuery: InlineTasksByMetadata;
}
